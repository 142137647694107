import React, {  useEffect, useState } from "react";
import {
  TransportTypeSelectorDeux,
} from "../Utils/Selectors/TransportTypeSelector";
import {
  formatShippingData,
  getDefaultDate,
  getTimeFromDate,
} from "../../utils";
import { TbPlus } from "react-icons/tb";
import TexteareaInput from "../Utils/UI/TexteareaInput";
import CommentaireComponent from "./CommentaireComponent";
import TypedeRoueSelector from "../Utils/Selectors/TypedeRoueSelector";

import DelaySelector from "../Utils/Selectors/DelaySelector";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { ShippingDetails } from "../../store/shippingDetails";
import { useAppContext } from "../../context";
import { validateShippingDetails } from "../../utils/validators";
import { toast } from "react-toastify";
import opsService from "../../services/ops/opsService";
import { ButtonComponent } from "../ButtonComponent";
import { formatDateForInput } from "../../utils/Functions/functions";
import clsx from "clsx";

export default function EditCourse({
  onAddTrackingModalOpen,
  onDispatchModalOpen,
}) {
  const { isAuth, setIsAuth } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateDetailInfoSupplement = ShippingDetails(
    (state) => state.updateDetailInfoSupplement
  );

  const coursePackage = ShippingDetails((state) => state.details.courses)[0]|| {};
  const expeditionDetail = ShippingDetails((state) => state.details);
  const updateCoursePackages = ShippingDetails(
    (state) => state.updateCoursePackages
  );
  const updateShipping = ShippingDetails((state) => state.updateShipping);
  const {ref, detail_info_supplementaire,information_importante,suivis, ...expedition } = expeditionDetail;
  const fetchDetails = ShippingDetails((state) => state.fetchDetails);


  const { course, shipping } = expedition;

  const {
    data: expeditionData,
    setFormValue,
    setData,
  } = useBasicFormValues(formatShippingData(expedition));

  const handleSubmit = async () => {
    try {
      const submitData = {
        ...expeditionData,
        course: {
          ...expeditionData.course,
          ...coursePackage,
        },
        shipping: {
          ...expeditionData.shipping,
          ...shipping,
        },
        isExpedition: shipping?.type_expedition === "expedition" ? true : false,
        interface: isAuth?.spaceName,
        info_supplementaire: detail_info_supplementaire,
      };

      delete submitData?.detail_info_supplementaire;

      const { error: _err } = validateShippingDetails(submitData);

      if (_err) {
        toast.error(_err, {
          position: "top-center",
          autoClose: 5000,
        });
        return;
      }

      if (shipping?.type_expedition === "expedition") {
        submitData.course = null;
        submitData.shipping.type_expedition = "expedition";
      } else {
        submitData.package = null;
        submitData.shipping.type_expedition = "courses";
      }
      setIsSubmitting(true);

      await opsService.updateShippingType(submitData);

      const { data } = await opsService.updateShipping(submitData);
      if (data.success) {
        toast.success(`Détails mis à jour avec succès`, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchDetails(ref)
      
      }

      setIsSubmitting(false)
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <fieldset>
        <legend>Informations sur le colis</legend>
        <div className="grid-cols-2 gap-2 space-y-2 lg:grid-cols-4 md:grid md:space-y-0">
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Référence interne client
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={shipping.customer_ref}
                onChange={(e) =>
                  updateShipping("customer_ref", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <TransportTypeSelectorDeux
              value={shipping.transport_type}
              onSelect={(v) => {
                updateShipping("transport_type", v.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Valeur commerciale / douane *
              </label>

              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={coursePackage.valeur_douane}
                onChange={(e) =>
                  updateCoursePackages("valeur_douane", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Description*
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={coursePackage?.package_description}
                onChange={(e) =>
                  updateCoursePackages("package_description", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date d'enlèvement *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={formatDateForInput(shipping.pickup_date)}
                onChange={(e) =>
                  updateShipping("pickup_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure d'enlèvement *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping.pickup_time}
                onChange={(e) =>
                  updateShipping("pickup_time", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date de livraison *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={formatDateForInput(shipping.delivery_date)}
                onChange={(e) =>
                  updateShipping("delivery_date", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure de livraison *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping.delivery_time}
                onChange={(e) =>
                  updateShipping("delivery_time", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Instruction d'enlèvement
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping.pickup_instruction}
                onChange={(e) =>
                  updateShipping("pickup_instruction", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Instruction de livraison
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={shipping.delivery_instruction}
                onChange={(e) =>
                  updateShipping("delivery_instruction", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Information importante{" "}
              </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    Array.isArray(information_importante)
                      ? information_importante[
                          information_importante.length - 1
                        ]?.information
                      : ""
                  }
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onDispatchModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="myInput1" className="uppercase">
                Mail tracking
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="myInput1"
                value={shipping.tracking_mail}
                onChange={(e) =>
                  updateShipping("tracking_mail", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Dernier Track
              </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    suivis?.length > 0
                      ? suivis[
                          suivis.length - 1
                        ]["details"]
                      : ""
                  }
                  disabled
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onAddTrackingModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            {" "}
            <TypedeRoueSelector
              roue={
                shipping?.type_de_roue
                  ? parseInt(shipping?.type_de_roue)
                  : parseInt(expedition?.type_de_roue)
              }
              onChange={(v) => {
                updateShipping("type_de_roue", v?.value);
              }}
            />
          </div>

          <div className={"col-md-3"}>
            <div className="form-group">
              <label htmlFor="field6" className="uppercase">
                Délais de livraison{" "}
              </label>
              <DelaySelector
                type_de_roue={
                  shipping?.type_de_roue
                    ? parseInt(shipping?.type_de_roue)
                    : parseInt(expedition?.type_de_roue)
                }
                value={coursePackage?.porteur}
                onShippingDataChange={(v) => updateCoursePackages("porteur", v)}
              />
              <small className="ml-3">Délais pour Paris intra-muros</small>
            </div>
          </div>

          <div className="col-md-3">
            <CommentaireComponent withLabel={true} value={expedition} />
           
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Articles Exotiques</label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={
                  detail_info_supplementaire?.articles_exotiques
                }
                onChange={(e) => {
                  updateDetailInfoSupplement(
                    "articles_exotiques",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Frais Dédouanement</label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={
                  detail_info_supplementaire?.frais_dedouanement
                }
                onChange={(e) => {
                  updateDetailInfoSupplement(
                    "frais_dedouanement",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="volume" className="mb-1 form-label">
                Volume m<sup>3</sup>
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="volume"
                value={detail_info_supplementaire?.volumes}
                onChange={(e) => {
                  updateDetailInfoSupplement("volumes", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4 ">
          <ButtonComponent title={isSubmitting?"Enregistrement...": "Enregistrer "} onClick={handleSubmit} buttonClass={clsx({"cursor-wait": isSubmitting})} disabled={isSubmitting} />
        </div>
      </fieldset>
    </>
  );
}
