import React, { useEffect, useState } from "react";
import {
  IncotermSelector,
  TransportTypeSelectorDeux,
} from "../Utils/Selectors/TransportTypeSelector";
import { TbPlus } from "react-icons/tb";
import { calculateWeightVolume, formatShippingData } from "../../utils";
import TexteareaInput from "../Utils/UI/TexteareaInput";
import CommentaireComponent from "./CommentaireComponent";
import PrincingInput from "../Utils/UI/PrincingInput";
import clsx from "clsx";
import { useAppContext } from "../../context";
import { ShippingDetails } from "../../store/shippingDetails";
import { formatDateForInput } from "../../utils/Functions/functions";
import { ButtonComponent } from "../ButtonComponent";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { validateShippingDetails } from "../../utils/validators";
import { toast } from "react-toastify";
import opsService from "../../services/ops/opsService";

export default function EditPackages({
  onAddTrackingModalOpen,
  onDispatchModalOpen,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const expeditionDetails = ShippingDetails((state) => state.details);
  const fetchDetails = ShippingDetails((state) => state.fetchDetails);
  const {
    ref,
    shipping,
    information_importante,
    suivis,
    detail_info_supplementaire,
    expeditor,
    receiver,
    ...expedition
  } = expeditionDetails;

  const expeditionPackage = ShippingDetails((state) => state.details.packages)[0] || {};


  const {
    data: expeditionData,
    setFormValue,
    setData,
  } = useBasicFormValues(formatShippingData(expedition));

  const updatePackages = ShippingDetails((state) => state.updatePackages);
  const updateShipping = ShippingDetails((state) => state.updateShipping);
  const updateDetailInfoSupplement = ShippingDetails(
    (state) => state.updateDetailInfoSupplement
  );

  const { isAuth, setIsAuth } = useAppContext();

  const onPackageDataChange = (key, value) => {
    updatePackages( key,value);
  };

  const weight_volume = calculateWeightVolume(expeditionPackage);

  const handleSubmit = async () => {
    try {
      const submitData = {
        ...expeditionData,
        package: {
          ...expeditionData.packages,
          ...expeditionPackage,
        },
        shipping: {
          ...expeditionData.shipping,
          ...shipping,
        },
        isExpedition: shipping?.type_expedition === "expedition" ? true : false,
        interface: isAuth?.spaceName,
        info_supplementaire: detail_info_supplementaire,
      };

      delete submitData?.detail_info_supplementaire;

      const { error: _err } = validateShippingDetails(submitData);

      if (_err) {
        toast.error(_err, {
          position: "top-center",
          autoClose: 5000,
        });
        return;
      }

      if (shipping?.type_expedition === "expedition") {
        submitData.course = null;
        submitData.shipping.type_expedition = "expedition";
      } else {
        submitData.package = null;
        submitData.shipping.type_expedition = "courses";
      }
      setIsSubmitting(true);

      await opsService.updateShippingType(submitData);

      const { data } = await opsService.updateShipping(submitData);
      if (data.success) {
        toast.success(`Détails mis à jour avec succès`, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchDetails(ref)
        
      }
      setIsSubmitting(false)
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const value = calculateWeightVolume(expeditionPackage);
    if (weight_volume !== value) {
      onPackageDataChange("weight_volume", value);
    }
  }, [expeditionPackage, onPackageDataChange, weight_volume]);

  return (
    <>
      <fieldset>
        <legend>Informations sur le colis</legend>
        <div className="grid-cols-4 gap-2 space-y-2 md:grid md:space-y-0 ">
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label ">Longueur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field1"
                  value={expeditionPackage?.length}
                  onChange={(e) => {
                    onPackageDataChange("length", e.target.value);
                  }}
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Largeur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field2"
                  value={expeditionPackage?.width}
                  onChange={(e) => onPackageDataChange("width", e.target.value)}
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Hauteur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field1"
                  value={expeditionPackage.height}
                  onChange={(e) =>
                    onPackageDataChange("height", e.target.value)
                  }
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Poids *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field2"
                  value={expeditionPackage?.weight}
                  onChange={(e) =>
                    onPackageDataChange("weight", e.target.value)
                  }
                />
                <span className="input-group-text">Kg</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Poids volumétrique *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full border border-gray-500 rounded-lg outline-none bg-gray-50 form-control"
                  name="field3"
                  value={weight_volume}
                  //onChange={e => onPackageDataChange("weight_volume", e.target.value)}
                  disabled
                />
                <span className="input-group-text">Kg</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Valeur commerciale*</label>
              <PrincingInput
                UpdateColisData={onPackageDataChange}
                colis={expeditionPackage}
                index={"none"}
              />
              {/* <div className="mb-3 input-group">
                <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field5"
                  value={expeditionPackage.value_in_douane || ""}
                  onChange={(e) =>
                    onPackageDataChange("value_in_douane", e.target.value)
                  }
                />
                <span className="input-group-text">€</span>
              </div> */}
            </div>
          </div>

          <div className="col-md-2">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Numero de devis
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={expeditionPackage?.nombre_devi || ""}
                onChange={(e) =>
                  onPackageDataChange("nombre_devi", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="volumes" className="mb-1 form-label">
                Volume m<sup>3</sup>
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="volumes"
                value={detail_info_supplementaire?.volumes}
                onChange={(e) => {
                  updateDetailInfoSupplement("volumes", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Montant de devis
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={expeditionPackage.montant_devi || ""}
                onChange={(e) =>
                  onPackageDataChange("montant_devi", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Référence interne client
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={shipping?.customer_ref}
                onChange={(e) => updateShipping("customer_ref", e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <TransportTypeSelectorDeux
              value={shipping?.transport_type}
              onSelect={(v) => {
                updateShipping("transport_type", v.value);
              }}
            />
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Dernier Track
              </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    suivis?.length > 0
                      ? suivis[suivis?.length - 1]["details"]
                      : ""
                  }
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onAddTrackingModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date d'enlèvement *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={formatDateForInput(shipping?.pickup_date)}
                // utcOffset('+01:00').
                onChange={(e) => updateShipping("pickup_date", e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure d'enlèvement *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping?.pickup_time}
                onChange={(e) => updateShipping("pickup_time", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date de livraison *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={formatDateForInput(shipping?.delivery_date)}
                onChange={(e) =>
                  updateShipping("delivery_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure de livraison *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping?.delivery_time}
                onChange={(e) =>
                  updateShipping("delivery_time", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Instruction d'enlèvement
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.pickup_instruction}
                onChange={(e) =>
                  updateShipping("pickup_instruction", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            {" "}
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Instruction de livraison
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={shipping?.delivery_instruction}
                onChange={(e) =>
                  updateShipping("delivery_instruction", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <IncotermSelector
              value={expeditionPackage?.incoterm}
              onSelect={(v) => {
                onPackageDataChange("incoterm", v.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Description
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={expeditionPackage.description || ""}
                onChange={(e) =>
                  onPackageDataChange("description", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Code donneur
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.code_donneur || ""}
                onChange={(e) => updateShipping("code_donneur", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Code receveur
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.code_receveur || ""}
                onChange={(e) =>
                  updateShipping("code_receveur", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="myInput1" className="uppercase">
                Mail tracking
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="myInput1"
                value={shipping?.tracking_mail}
                onChange={(e) =>
                  updateShipping("tracking_mail", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">
                Information importante{" "}
              </label>

              <div className="relative rounded-md shadow-sm">
                <textarea
                  rows={3}
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full overflow-auto bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    Array.isArray(information_importante)
                      ? information_importante[
                          information_importante.length - 1
                        ]?.information
                      : ""
                  }
                />
                <div
                  className={clsx(
                    {
                      "bg-paris text-white": isAuth?.spaceName === "paris",
                      "bg-medical text-white": isAuth?.spaceName === "medical",
                      "bg-service text-white": isAuth?.spaceName === "service",
                    },
                    "absolute inset-y-0 right-0 flex items-center pr-3 rounded-e-lg"
                  )}
                >
                  <span
                    className="font-medium sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onDispatchModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <CommentaireComponent withLabel={true} value={expeditionDetails} />
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Articles Exotiques</label>
              <TexteareaInput
                rows={1}
                id="text"
                value={detail_info_supplementaire?.articles_exotiques}
                onChange={(e) => {
                  updateDetailInfoSupplement(
                    "articles_exotiques",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Frais Dédouanement</label>
              <TexteareaInput
                rows={1}
                id="text"
                value={detail_info_supplementaire?.frais_dedouanement}
                onChange={(e) => {
                  updateDetailInfoSupplement(
                    "frais_dedouanement",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4 ">
          <ButtonComponent title={isSubmitting?"Enregistrement...": "Enregistrer "} onClick={handleSubmit} buttonClass={clsx({"cursor-wait": isSubmitting})} disabled={isSubmitting} />
        </div>
      </fieldset>
    </>
  );
}
