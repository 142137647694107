import React, { useEffect, useState } from "react";
import { expeditionTable } from "../../utils/SpaceData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Head from "../../components/HeadComponent/Head";
import { toast } from "react-toastify";
import { LoadingComponent } from "../../utils/loadingUtils";
import Accordion from "../../components/accordionComponent/AccordionComponent";
import {
    actuallDate,
    urlSplit,
} from "../../utils/Functions/otherFunctions";
import {
    exportStep_5,
    verifyProformaData,
    verifyProformaHeader,
    verifyStep_4,
    verifyStepCourse,
} from "../../utils/Functions/verifyDropdown";
import useDisclosure from "../../hooks/useDisclosure";
import ModalComponent from "../../components/Utils/Modal/Index";
import PolitiqueConfidentiakiteComponent from "../../components/ConditionGeneralComponent/PolitiqueConfidentiakiteComponent";
import ConditionGeneralComponent from "../../components/ConditionGeneralComponent/ConditionGeneralComponent";
import clsx from "clsx";
import { useAppContext } from "../../context/index.jsx";
import { ButtonComponent } from "../../components/ButtonComponent.jsx";
import { Switch } from "@headlessui/react";
import { ShippingUser } from "./shippingUser.jsx";
import { ClientShippingRefference } from "./ClientShippingRefference.jsx";
import { parseName } from "../../utils/Functions/functions.js";
import { ShippingPickUpDelivery } from "./shippingPickUpDelivery.jsx";
import { ExpeditionStore } from "../../store/expedition.js";
import { ShippingPackage } from "./shippingPackage.jsx";
import { ErrorStore } from "../../store/inputError.js";
import ExpeditionService from "../../services/expedition/ExpeditionService.js";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant.js";
import { ShippingCoursesPackages } from "./shippingCoursesPackages.jsx";
import { OpsShippingRefference } from "./shippingRefference.jsx";
import { ShippingProforma } from "./shippingProforma.jsx";
import { Emballage } from "./Emballage.jsx";


export const CreateShhing = React.memo((props) => {
    // Paramètre URL
    const { ExpeditionType } = useParams();
    // Les states
    const [errorInformation, setErrorInformation] = useState({
        field: "",
        message: "",
    });
    const navigate = useNavigate();
    const [overlay, setOverlay] = useState(false);
    const [espace, setEspace] = useState("");
    const [delais, setDelais] = useState(null);
    const [proforma, setproforma] = useState(false);
    const { pathname } = useLocation();
    const { isAuth, setIsAuth } = useAppContext();
    const [errorStep, setErrorStep] = useState([])


    const infosSupp = ExpeditionStore((state) => state?.infoSupData);
    const setInfosSupp = ExpeditionStore((state) => state?.setSupData);
    const resetAllShippingData = ExpeditionStore((state) => state?.resetAllShippingData);
    const setActiveAccordion = ExpeditionStore((state) => state?.setActiveStepper);
    const packagesData = ExpeditionStore((state) => state?.packagesData);
    const addPackagesData = ExpeditionStore((state) => state?.addPackagesData);
    const activeAccordionList = ExpeditionStore((state) => state?.validStepperList);
    const activeAccordion = ExpeditionStore((state) => state?.activeStepper);
    const setUserData = ExpeditionStore((state) => state?.setUserData);
    const allStepperIsValid = ExpeditionStore((state) => state?.validData);
    const user = ExpeditionStore((state) => state?.user);
    const expeditor = user['expeditor'];
    const receiver = user['receiver'];
    const senderBack = user['expeditorBack'];
    const receiverBack = user['receiverBack'];
    const deliveryPickupData = ExpeditionStore((state) => state?.deliveryPickupData);
    const coursePackageData = ExpeditionStore((state) => state?.coursePackageData);
    const setWithBack = ExpeditionStore((state) => state?.setWithBack);
    const withBack = ExpeditionStore((state) => state?.withBack);
    const maxStepper = ExpeditionStore((state) => state?.maxStepper);
    const { proformaList, proformaHeader } = ExpeditionStore((state) => state.proforma);
    const withEmballage = ExpeditionStore((state) => state.withEmballage);

    const {
        expedition: TypeExpedition,
    } = urlSplit();

    // **************** Liste des stats ***********************
    // Pour stocker les données sur l'expedititeur
    const [cgvPolitique, setCgvPolitique] = useState({
        condition: false,
        politique: false,
    });

    const updateCgvPolitique = (key, value) => {
        setCgvPolitique({
            ...cgvPolitique,
            [key]: value,
        });
    };

    // ********************************* ETAT GLOBALE FIN *****************************************

    //**********************   Les fonctions  ******************************************

    // Pour changer l'état d'un accordion
    const toggleAccordion = (index) => {
        setActiveAccordion(index);
        let temporaryList = [...activeAccordionList];
        if (!temporaryList?.includes(index)) {
            temporaryList.push(index);
        }
    };

    const resetAllState = () => {
        resetAllShippingData()
        setErrorStep([])

        setCgvPolitique({
            condition: false,
            politique: false,
        });
    };


    const { isOpen, onClose, onOpen } = useDisclosure();

    const [modalData, setmodalData] = useState("");

    const showModal = (value) => {
        setmodalData(value);
        onOpen(true);
    };


    const formData = () => {
        const infoSupData = infosSupp;
        let newExpeditionData = new FormData();
        if (infoSupData.bill_center_id) {
            newExpeditionData.append(
                "shipping-info[billing_center_id]",
                infoSupData.bill_center_id?.value ?? infoSupData.bill_center_id
            );
        }

        if (isAuth?.userRole === "ops" && proforma) {
            newExpeditionData.append(
                "proforma[nature]",
                proformaHeader?.nature
            );
            newExpeditionData.append(
                "proforma[num_proforma]",
                proformaHeader?.num_proforma
            );
            newExpeditionData.append(
                "proforma[date_export]",
                proformaHeader?.date_export
            );

            proformaList.map((item, index) => {
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][description]`,
                    item.description
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][qte]`,
                    item.qte
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][composition]`,
                    item.composition
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][hs_code]`,
                    item.hs_code
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][manifacturer]`,
                    item.manifacturer
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][prix_unitaire]`,
                    item.prix_unitaire
                );
                newExpeditionData.append(
                    `proforma[proforma_line][${index}][origin]`,
                    item.origin
                );
            });
        }

        // newExpeditionData.append("shipping-info[user_id]", user.id);
        newExpeditionData.append(
            "shipping-info[user_id]",
            isAuth?.userRole === "client" ? isAuth.id : infoSupData?.username?.user_id ?? isAuth.id
        );
        newExpeditionData.append(
            "shipping-info[type_de_roue]",
            parseInt(infoSupData?.type_de_roue?.value) || 0
        );
        newExpeditionData.append(
            "shipping-info[customer_ref]",
            infoSupData.customer_ref
        );
        newExpeditionData.append("shipping-info[created_at]", actuallDate());


        newExpeditionData.append(
            "shipping-info[pickup_date]",
            deliveryPickupData['pickup']['date']
        );
        newExpeditionData.append(
            "shipping-info[delivery_date]",
            deliveryPickupData["delivery"]["date"]
        );
        newExpeditionData.append(
            "shipping-info[pickup_instruction]",
            deliveryPickupData["pickup"]["instruction"]
        );
        newExpeditionData.append(
            "shipping-info[delivery_instruction]",
            deliveryPickupData["delivery"]["instruction"]
        );
        newExpeditionData.append(
            "shipping-info[tracking_mail]",
            infoSupData.tracking_mail
        );
        newExpeditionData.append(
            "shipping-info[expeditor][civility]",
            expeditor?.civility ?? "M"
        );
        newExpeditionData.append(
            "shipping-info[expeditor][company]",
            expeditor.company && expeditor.company !== ""
                ? expeditor.company
                : expeditor?.firstName + " " + expeditor?.lastName
        );
        newExpeditionData.append(
            "shipping-info[expeditor][fullname]",
            expeditor.firstName + " " + expeditor.lastName
        );
        newExpeditionData.append(
            "shipping-info[expeditor][address1]",
            expeditor.address1
        );
        newExpeditionData.append(
            "shipping-info[expeditor][address2]",
            expeditor.address2
        );
        newExpeditionData.append(
            "shipping-info[expeditor][country]",
            getCountryNameOrCode(expeditor.country)
        );
        newExpeditionData.append(
            "shipping-info[expeditor][postalCode]",
            expeditor.postalCode
        );
        newExpeditionData.append(
            "shipping-info[expeditor][city]",
            expeditor.city
        );
        newExpeditionData.append(
            "shipping-info[expeditor][phonecode]",
            expeditor.phonecode || ""
        );
        newExpeditionData.append(
            "shipping-info[expeditor][phone1]",
            expeditor.phone1
        );
        newExpeditionData.append(
            "shipping-info[expeditor][phone2]",
            expeditor.phone2
        );
        newExpeditionData.append(
            "shipping-info[expeditor][email]",
            expeditor.email
        );
        newExpeditionData.append(
            "shipping-info[expeditor][lastName]",
            expeditor?.lastName
        );

        newExpeditionData.append(
            "shipping-info[expeditor][firstName]",
            expeditor?.firstName
        );

        newExpeditionData.append(
            "shipping-info[expeditor][update-contact]",
            expeditor["update-contact"] || 0
        );
        newExpeditionData.append(
            "shipping-info[expeditor][save-contact]",
            expeditor["save-contact"] || 0
        );

        if (expeditor["update-contact"] === 1) {
            newExpeditionData.append(
                "shipping-info[expeditor][contact_id]",
                expeditor["id"] || 0
            );
        }

        newExpeditionData.append(
            "shipping-info[receiver][update-contact]",
            receiver["update-contact"] || 0
        );
        newExpeditionData.append(
            "shipping-info[receiver][save-contact]",
            receiver["save-contact"] || 0
        );

        if (receiver["update-contact"] === 1) {
            newExpeditionData.append(
                "shipping-info[receiver][contact_id]",
                receiver["id"] || 0
            );
        }

        newExpeditionData.append(
            "shipping-info[receiver][civility]",
            receiver?.civility ?? "M"
        );
        newExpeditionData.append(
            "shipping-info[receiver][lastName]",
            receiver?.lastName
        );

        newExpeditionData.append(
            "shipping-info[receiver][firstName]",
            receiver?.firstName
        );

        newExpeditionData.append(
            "shipping-info[receiver][company]",
            receiver.company !== ""
                ? receiver.company
                : receiver?.firstName + " " + receiver?.lastName
        );
        newExpeditionData.append(
            "shipping-info[receiver][fullname]",
            receiver.firstName + " " + receiver.lastName
        );
        newExpeditionData.append(
            "shipping-info[receiver][address1]",
            receiver.address1
        );
        newExpeditionData.append(`shipping-info[devises]`, packagesData[0]?.devises);
        newExpeditionData.append(
            "shipping-info[receiver][address2]",
            receiver.address2 ? receiver.address2 : ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][country]",
            getCountryNameOrCode(receiver.country)
        );
        newExpeditionData.append(
            "shipping-info[receiver][postalCode]",
            receiver.postalCode ? receiver.postalCode : ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][city]",
            receiver.city ? receiver.city : ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][phonecode]",
            receiver.phonecode || ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][phone1]",
            receiver.phone1 ? receiver.phone1 : ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][phone2]",
            receiver.phone2 ? receiver.phone2 : ""
        );
        newExpeditionData.append(
            "shipping-info[receiver][email]",
            receiver.email ? receiver.email : ""
        );
        newExpeditionData.append(
            "shipping-info[pickup_time]",
            deliveryPickupData["pickup"]["time"]
        );
        newExpeditionData.append(
            "shipping-info[delivery_time]",
            deliveryPickupData["delivery"]["time"]
        );
        newExpeditionData.append("shipping-info[ref]", "");
        newExpeditionData.append(
            "shipping-info[username]",
            infoSupData.username?.value ?? infoSupData.username
        );
        newExpeditionData.append(
            "shipping-info[code_donneur]",
            infoSupData.code_donneur
        );
        newExpeditionData.append(
            "shipping-info[code_receveur]",
            infoSupData.code_receveur
        );
        newExpeditionData.append("shipping-info[manifest]", "");
        newExpeditionData.append(
            "shipping-info[isops]",
            isAuth?.userRole === "client" ? 0 : 1
        );
        newExpeditionData.append("data-package[0][shipping_id]", "");
        packagesData.map((item, index) => {
            newExpeditionData.append(
                `data-package[${index}][length]`,
                item.length
            );
            newExpeditionData.append(
                `data-package[${index}][width]`,
                item.width
            );
            newExpeditionData.append(
                `data-package[${index}][height]`,
                item.height
            );
            newExpeditionData.append(
                `data-package[${index}][weight]`,
                item.weight
            );
            newExpeditionData.append(
                `data-package[${index}][weight_volume]`,
                item.weight_volume
            );
            newExpeditionData.append(
                `data-package[${index}][description]`,
                item.description
            );
            newExpeditionData.append(
                `data-package[${index}][incoterm]`,
                item.incoterm
            );
            newExpeditionData.append(
                `data-package[${index}][value_in_douane]`,
                item.value_in_douane
            );
            if (item.fichiers && item.fichiers.length > 0) {
                for (let i in item.fichiers) {
                    newExpeditionData.append(
                        `data-package[${index}][documents][]`,
                        item.fichiers[i].file
                    );
                }
            }
            // else {
            //   newExpeditionData.append(`data-package[${index}][documents][]`, );
            // }

            newExpeditionData.append(
                `data-package[${index}][created_at]`,
                deliveryPickupData["pickup"]["date"]
            );
            newExpeditionData.append(`data-package[${index}][ref]`, " derss");
        });
        newExpeditionData.append(
            "data-course[package_description]",
            coursePackageData?.package_description
        );

        newExpeditionData.append(
            "data-course[additional_insurance_amount]",
            coursePackageData?.additional_insurance_amount
        );

        newExpeditionData.append(
            "data-course[additional_insurance]",
            coursePackageData?.additional_insurance_amount
        );

        if (coursePackageData?.documents.length > 0 && coursePackageData?.documents[0]?.file) {
            for (let i in coursePackageData?.documents) {
                newExpeditionData.append(
                    "data-course[documents][]",
                    coursePackageData?.documents[i]['file']
                );
            }
        }

        // else {
        //   newExpeditionData.append(
        //     "data-course[documents][]",
        //     null
        //   );
        // }

        newExpeditionData.append("data-course[created_at]", actuallDate());

        newExpeditionData.append(
            "data-course[valeur_douane]",
            coursePackageData?.valeur_douane
        );

        newExpeditionData.append("data-course[shipping_id]", "");
        newExpeditionData.append("data-course[porteur]", coursePackageData?.delais);

        newExpeditionData.append(
            "type-expedition",
            (ExpeditionType !== "import" && ExpeditionType !== "export") ? "courses" : "expedition"
        );

        newExpeditionData.append("interface", isAuth?.spaceName);

        if (withBack) {

            // Sender Back

            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][civility]",
                senderBack?.civility ?? "M"
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][lastName]",
                senderBack?.lastName
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][firstName]",
                senderBack?.firstName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][civility]",
                senderBack?.civility ?? "M"
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][company]",
                senderBack?.company !== ""
                    ? senderBack?.company
                    : senderBack?.firstName + " " + senderBack?.lastName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][fullname]",
                senderBack.firstName + " " + senderBack.lastName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][address1]",
                senderBack.address1
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][address2]",
                senderBack.address2 ? senderBack.address2 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][country]",
                getCountryNameOrCode(senderBack.country)
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][postalCode]",
                senderBack.postalCode ? senderBack.postalCode : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][city]",
                senderBack.city ? senderBack.city : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][phonecode]",
                senderBack.phonecode || ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][phone1]",
                senderBack.phone1 ? senderBack.phone1 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][phone2]",
                senderBack.phone2 ? senderBack.phone2 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][email]",
                senderBack.email ? senderBack.email : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][update-contact]",
                senderBack["update-contact"] || 0
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][expeditor][save-contact]",
                senderBack["save-contact"] || 0
            );



            // Back Receiver
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][civility]",
                receiverBack?.civility ?? "M"
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][lastName]",
                receiverBack?.lastName
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][firstName]",
                receiverBack?.firstName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][civility]",
                receiverBack?.civility ?? "M"
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][company]",
                receiverBack?.company !== ""
                    ? receiverBack?.company
                    : receiverBack?.firstName + " " + receiverBack?.lastName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][fullname]",
                receiverBack.firstName + " " + receiverBack.lastName
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][address1]",
                receiverBack.address1
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][address2]",
                receiverBack.address2 ? receiverBack.address2 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][country]",
                getCountryNameOrCode(receiverBack.country)
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][postalCode]",
                receiverBack.postalCode ? receiverBack.postalCode : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][city]",
                receiverBack.city ? receiverBack.city : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][phonecode]",
                receiverBack.phonecode || ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][phone1]",
                receiverBack.phone1 ? receiverBack.phone1 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][phone2]",
                receiverBack.phone2 ? receiverBack.phone2 : ""
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][email]",
                receiverBack.email ? receiverBack.email : ""
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][update-contact]",
                receiverBack["update-contact"] || 0
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][receiver][save-contact]",
                receiverBack["save-contact"] || 0
            );


            // Shippinf delivery back

            newExpeditionData.append(
                "shipping-info[back-and-forth][pickup_date]",
                deliveryPickupData['pickupBack']['date']
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][delivery_date]",
                deliveryPickupData["deliveryBack"]["date"]
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][pickup_instruction]",
                deliveryPickupData["pickupBack"]["instruction"]
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][delivery_instruction]",
                deliveryPickupData["deliveryBack"]["instruction"]
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][pickup_time]",
                deliveryPickupData["pickupBack"]["time"]
            );
            newExpeditionData.append(
                "shipping-info[back-and-forth][delivery_time]",
                deliveryPickupData["deliveryBack"]["time"]
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][type_de_roue]",
                parseInt(infoSupData?.type_de_roueBack?.value) || 0
            );

            newExpeditionData.append(
                "shipping-info[back-and-forth][porteur]",
                parseInt(coursePackageData?.delaisBack) || 0
            );


            // 'shipping-info.back-and-forth.type_de_roue' => 'string|max:255',
            // 'data-course.back-and-forth.porteur' => 'string|max:255',

        }

        newExpeditionData.append("is-back-and-forth", withBack ? 1 : 0)

        return newExpeditionData
    }


    const handleSubmit = () => {
        const expeditionTypeLowerCase = ExpeditionType.toLowerCase();
        const keysTable = Object.keys(allStepperIsValid);
        const errorStepList = Object.keys(allStepperIsValid).filter(key => !allStepperIsValid[key]);

        const { error: ErrorColis, errorData: MessageColis } = exportStep_5(
            packagesData,
            isAuth?.spaceName
        );

        const { error: ErrorCourse, errorData: MessageCourse } =
            verifyStepCourse(coursePackageData);


        if (
            ((expeditionTypeLowerCase === "import" ||
                expeditionTypeLowerCase === "export") &&
                !ErrorColis) ||
            (expeditionTypeLowerCase !== "import" &&
                expeditionTypeLowerCase !== "export" &&
                !ErrorCourse)
        ) {
            let authorizeSubmission =
                isAuth?.userRole === "client" &&
                    cgvPolitique.condition &&
                    cgvPolitique.politique
                    ? true
                    : isAuth?.userRole === "admin" || isAuth?.userRole === "ops"
                        ? true
                        : false;

            if (authorizeSubmission) {

                const toastsId = toast.loading("Enregistrement en cours ...", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                // newExpeditionData.append("shipping-info['is-back-and-forth']", withBack ? 1 : 0)

                const shippingData = formData();

                ExpeditionService.createExpedition(shippingData).then(
                    (res) => {
                        let { data, success, message } = res.data;
                        if (success) {
                            toast.update(toastsId, {
                                render: "Enregistrement effectué avec succès",
                                type: "success",
                                isLoading: false,
                                autoClose: 1000,
                                position: "top-center",
                            });

                            setTimeout(() => {

                                navigate(`/${isAuth?.userRole}/${isAuth?.spaceName}/dash`);

                            }, 500);
                        } else {
                            toast.update(toastsId, {
                                render: message,
                                type: "error",
                                isLoading: false,
                                autoClose: 5000,
                            });
                        }
                    },
                    (error) => {
                        toast.update(toastsId, {
                            render: "Une erreur est survenue lors de l'enregistrement",
                            type: "error",
                            isLoading: false,
                            autoClose: 500,
                        });
                    }
                );

            } else {

                const response = expeditionTypeLowerCase === "import" || expeditionTypeLowerCase === "export" ? MessageColis : MessageCourse;
                setErrorStep([...errorStepList])
                toast.error("Les conditions et la politique sont obligatoires", {
                    position: "top-center",
                    autoClose: 2500,
                });
            }

        }
        else {
            const response = expeditionTypeLowerCase === "import" || expeditionTypeLowerCase === "export" ? MessageColis : MessageCourse;
            setErrorStep([...errorStepList])
            toast.error(response?.message, {
                position: "top-center",
                autoClose: 2500,
            });
        }
    }

    useEffect(() => {
        resetAllState();

        let espace = pathname.split("/");
        espace = espace[espace.length - 2];


        if (isAuth?.userRole === "client") {
            let data = { ...infosSupp, username: isAuth?.userRole === "client" ? isAuth?.name : "" };
            setInfosSupp({ ...data });

            if (parseInt(isAuth["2_roues"]) === 1) {
                data["type_de_roue"] = {
                    label: "DEUX ROUES",
                    value: 2,
                };
                setInfosSupp({ ...data });
            }

            if (parseInt(isAuth["vt"]) === 1) {
                data["type_de_roue"] = {
                    label: "QUATRE ROUES",
                    value: 4,
                };
                setInfosSupp({ ...data });
            }

            const { firstName, lastName } = parseName(isAuth?.fullname, isAuth?.name, isAuth);

            let newData = {
                ...isAuth,
                firstName,
                lastName,
                phone1: isAuth?.telephone1,
                phone2: isAuth?.telephone2,
                company: isAuth?.entreprise_physique,
                postalCode: isAuth?.postal_code
            }

            if (espace === "export" || espace === "courses-a-courses") {
                setUserData({ ...newData, civility: newData?.civility ?? "M" }, 'expeditor', 'normal');
            }

            if (espace === "import") {
                setUserData({ ...newData, civility: newData?.civility ?? "M" }, 'receiver', 'normal');
                // setReceiver({ ...user });
            }
        }

        // ********************************
        setEspace(window.location.pathname.split("/")[2]);

        if (
            ExpeditionType === undefined ||
            !expeditionTable?.includes(ExpeditionType)
        ) {
            navigate("/not-found");
        }

    }, [window.location.href]);

    const returnAccordionPosition = (value) => {
        // if (withEmballage) {
        //     return value + 1
        // } else {
        //     return value
        // }

        return value
    }


    return (
        <>
            <Head espace={isAuth?.spaceName} >
                <title>Exnet-{isAuth?.spaceName} | Nouvelle Expédition</title>
            </Head>
            <LoadingComponent overlay={overlay} />

            <div className={""}>
                {/* Pour les référende de l'expédition */}
                <div className="">
                    {/* <div className="mx-2">
                        <SwitchEmballageComponent />
                    </div>

                    {withEmballage &&
                        <Accordion
                            title={"Emballage".toUpperCase()}
                            isOpen={activeAccordion === 1 ? true : false}
                            toggleAccordion={toggleAccordion}
                            activeAccordionList={activeAccordionList}
                            index={1}
                            isError={errorStep.indexOf("emballage") !== -1}
                        >
                            <div className="row">
                                <Emballage />
                            </div>
                        </Accordion>
                    } */}


                    <Accordion
                        title={TypeExpedition}
                        isOpen={activeAccordion === returnAccordionPosition(1) ? true : false}
                        toggleAccordion={toggleAccordion}
                        activeAccordionList={activeAccordionList}
                        index={1}
                        isError={errorStep.indexOf("reference") !== -1}
                    >
                        <div className="row">

                            {isAuth?.userRole === "client" ? (
                                <ClientShippingRefference
                                />
                            ) : (
                                <OpsShippingRefference
                                    type={ExpeditionType}

                                />
                            )}
                        </div>
                    </Accordion>

                    <Accordion
                        title={"DéTAILS EXPéDITEUR".toUpperCase()}
                        isOpen={activeAccordion === returnAccordionPosition(2) ? true : false}
                        toggleAccordion={toggleAccordion}
                        activeAccordionList={activeAccordionList}
                        index={2}
                        isError={errorStep.indexOf("expeditor") !== -1}
                    >
                        {/* Pour l'xpéditeur */}
                        <div className="row">
                            <ShippingUser
                                toggleAccordion={toggleAccordion}
                                contactType={"expeditor"}
                                shippingProcess={"normal"}
                                key={"expeditor"}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        title={"DéTAILS DESTINATAIRE".toUpperCase()}
                        isOpen={activeAccordion === returnAccordionPosition(3) ? true : false}
                        toggleAccordion={toggleAccordion}
                        activeAccordionList={activeAccordionList}
                        index={3}
                        isError={errorStep.indexOf("receiver") !== -1}
                    >
                        {/* Pour les receveur */}
                        <div className="row">
                            <ShippingUser
                                toggleAccordion={toggleAccordion}
                                contactType={"receiver"}
                                shippingProcess={"normal"}
                                key={"receiver"}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        title={"DéTAILS ENLèVEMENT ET LIVRAISON".toUpperCase()}
                        isOpen={activeAccordion === returnAccordionPosition(4) ? true : false}
                        toggleAccordion={toggleAccordion}
                        activeAccordionList={activeAccordionList}
                        index={4}
                        isError={errorStep.indexOf("pickupDelivery") !== -1}
                    >
                        {/* Pour les détails de l'expédition */}
                        <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid">
                            <div className="col-span-2">
                                <ShippingPickUpDelivery
                                    ExpeditionType={ExpeditionType}
                                    kind={"enlevement"}
                                    toggleAccordion={toggleAccordion}
                                    delais={delais}
                                    shippingProcess="normal"
                                    key={1}
                                />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        title={"DéTAILS COLIS".toUpperCase()}
                        isOpen={activeAccordion === returnAccordionPosition(5) ? true : false}
                        toggleAccordion={toggleAccordion}
                        activeAccordionList={activeAccordionList}
                        index={5}
                        isError={errorStep.indexOf("packages") !== -1}
                    >
                        <>
                            {/* Pour les articles */}
                            {ExpeditionType === "import" || ExpeditionType === "export" ? (
                                <>
                                    {packagesData?.map((item, index) => (
                                        <div className="row">
                                            <div className="mx-2">
                                                <SwitchEmballageComponent />
                                            </div>
                                            {withEmballage &&
                                                <div className="row">
                                                    <Emballage />
                                                </div>
                                            }
                                            <ShippingPackage
                                                index={index}
                                                item={item}
                                                key={`cle_${index}`}
                                            />


                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <section >
                                        <ShippingCoursesPackages />

                                        <div>
                                            <SwitchComponent action={() => setWithBack(!withBack)} state={withBack} />
                                        </div>
                                    </section>
                                </>
                            )}

                            {(ExpeditionType === "import" || ExpeditionType === "export") && (
                                <>
                                    <div className={"flex flex-row-reverse my-2"}>
                                        <ButtonComponent title={"Ajouter Colis"} onClick={addPackagesData} />
                                    </div>

                                </>
                            )}


                        </>
                    </Accordion>

                    {withBack && <>
                        <Accordion
                            title={"DéTAILS EXPéDITEUR RETOUR".toUpperCase()}
                            isOpen={activeAccordion === returnAccordionPosition(6) ? true : false}
                            toggleAccordion={toggleAccordion}
                            activeAccordionList={activeAccordionList}
                            index={7}
                            isError={errorStep.indexOf("expeditorBack") !== -1}
                        >
                            {/* Pour l'xpéditeur */}
                            <div className="row">
                                <ShippingUser
                                    toggleAccordion={toggleAccordion}
                                    contactType={"expeditorBack"}
                                    shippingProcess={"back"}
                                    key={"expeditorBack"}
                                />
                            </div>
                        </Accordion>

                        <Accordion
                            title={"DéTAILS DESTINATAIRE retour".toUpperCase()}
                            isOpen={activeAccordion === returnAccordionPosition(7) ? true : false}
                            toggleAccordion={toggleAccordion}
                            activeAccordionList={activeAccordionList}
                            index={8}
                            isError={errorStep.indexOf("receiver") !== -1}
                        >
                            {/* Pour les receveur */}
                            <div className="row">
                                <ShippingUser
                                    toggleAccordion={toggleAccordion}
                                    contactType={"receiverBack"}
                                    shippingProcess={"back"}
                                    key={"receiverBack"}
                                />
                            </div>
                        </Accordion>

                        <Accordion
                            title={"DéTAILS ENLèVEMENT ET LIVRAISON RETOUR".toUpperCase()}
                            isOpen={activeAccordion === returnAccordionPosition(8) ? true : false}
                            toggleAccordion={toggleAccordion}
                            activeAccordionList={activeAccordionList}
                            index={9}
                            isError={errorStep.indexOf("pickupDelivery") !== -1}
                        >
                            {/* Pour les détails de l'expédition */}
                            <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid">
                                <div className="col-span-2">
                                    <ShippingPickUpDelivery
                                        ExpeditionType={ExpeditionType}
                                        kind={"enlevement"}
                                        toggleAccordion={toggleAccordion}
                                        delais={delais}
                                        shippingProcess="back"
                                        key={1}
                                    />
                                </div>
                            </div>
                        </Accordion>


                    </>}


                </div>

                {(activeAccordion === maxStepper || maxStepper === 8 || maxStepper === 9) && (
                    <>
                        {isAuth?.userRole !== "client" && (
                            <>
                                <div className="flex-row-reverse p-1 mb-5 row">
                                    {activeAccordion === 5 && (
                                        <div>
                                            <ButtonComponent title={"Proforma"} onClick={() => setproforma(!proforma)} />
                                        </div>

                                    )}

                                    {proforma && (
                                        <ShippingProforma />
                                    )}
                                </div>
                            </>
                        )}

                        {isAuth?.userRole === "client" && (
                            <>

                                <div className="flex flex-wrap items-center mb-6">
                                    <div className="flex items-start mr-12">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="restreint"
                                                type="checkbox"
                                                checked={cgvPolitique.condition}
                                                onChange={(e) => {
                                                    updateCgvPolitique(
                                                        "condition",
                                                        !cgvPolitique.condition
                                                    );
                                                }}
                                                className="w-4 h-4 border border-gray-300 rounded-lg bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                            />
                                        </div>
                                        <label
                                            htmlFor="restreint"
                                            className="ml-2 text-sm font-medium text-gray-900 "
                                        >
                                            <span
                                                className={" pointer"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showModal("condition");
                                                }}
                                            >
                                                {" "}
                                                En cochant cette case, je reconnais avoir pris
                                                connaissance des{" "}
                                                <span className={" underline "}>
                                                    Conditions Générales de Vente et de Transport EXNET
                                                </span>{" "}
                                                {isAuth?.spaceName.toUpperCase()}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {errorInformation.field === "condition" && (
                                    <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                                        {errorInformation.message}
                                    </p>
                                )}

                                <div className="flex flex-wrap items-center mb-6">
                                    <div className="flex items-start mr-12">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="restreint"
                                                type="checkbox"
                                                checked={cgvPolitique.politique}
                                                onChange={(e) => {
                                                    updateCgvPolitique(
                                                        "politique",
                                                        !cgvPolitique.politique
                                                    );
                                                }}
                                                className="w-4 h-4 border border-gray-300 rounded-lg bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                            />
                                        </div>
                                        <label
                                            htmlFor="restreint"
                                            className="ml-2 text-sm font-medium text-gray-900 pointer "
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showModal("politique");
                                            }}
                                        >
                                            <span>
                                                {" "}
                                                En cochant cette case, je reconnais avoir pris
                                                connaissance du{" "}
                                                <span className={"underline"}>
                                                    traitement des données personnelles
                                                </span>{" "}
                                                du site, et les accepte sans réserve.
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                {errorInformation.field === "politique" && (
                                    <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                                        {errorInformation.message}
                                    </p>
                                )}
                            </>
                        )}

                        <div className="flex-row-reverse mt-5 row pe-5">
                            <div className="col-6 col-md-2">
                                <div className="form-group">
                                    <button
                                        // disabled={activeAccordion === maxStepper ? false : true}
                                        type="button"
                                        className={clsx(
                                            {
                                                " cursor-not-allowed ": activeAccordion !== 5,
                                                "bg-paris": isAuth?.spaceName === "paris",
                                                "bg-medical": isAuth?.spaceName === "medical",
                                                "bg-service": isAuth?.spaceName === "service",
                                                "bg-admin": isAuth?.spaceName === "admin"
                                            },
                                            "text-sm rounded-md px-4 py-2 text-white"
                                        )}
                                        onClick={handleSubmit}

                                    >
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>


                )}

            </div>

            <ModalComponent isOpen={isOpen} onClose={onClose} size="2xl" title={""}>
                {modalData === "condition" ? (
                    <ConditionGeneralComponent />
                ) : (
                    <PolitiqueConfidentiakiteComponent />
                )}
            </ModalComponent>
        </>
    );
});

const SwitchComponent = () => {
    const { isAuth, setIsAuth } = useAppContext();

    const setWithBack = ExpeditionStore((state) => state.setWithBack);
    const withBack = ExpeditionStore((state) => state.withBack);
    const setMaxStepper = ExpeditionStore((state) => state?.setMaxStepper);
    const setAllUserData = ExpeditionStore((state) => state?.setAllUserData);
    const setValidStep = ExpeditionStore((state) => state?.setValidStep);
    const maxStepper = ExpeditionStore((state) => state?.maxStepper);

    const user = ExpeditionStore((state) => state?.user);
    const expeditor = user['expeditor'];
    const receiver = user['receiver'];

    const backShipping = () => {
        setWithBack(!withBack)
        if (!withBack) {
            setMaxStepper(maxStepper + 3)
            setAllUserData({
                expeditor: expeditor,
                expeditorBack: receiver,
                receiver: receiver,
                receiverBack: expeditor
            });

            setValidStep([
                6, 7, 8
            ])
        } else {
            setMaxStepper(maxStepper - 3)

        }
    }

    return (
        <div className="flex items-center justify-between my-2">
            <span className='text-lg font-bold' >Programmer une commande retour</span>
            <Switch
                checked={withBack}

                onChange={backShipping}

                className={clsx({
                    "bg-paris": isAuth?.spaceName === "paris",
                    "bg-medical ": isAuth?.spaceName === "medical",
                    "bg-service ": isAuth?.spaceName === "service",
                    "bg-admin ": isAuth?.spaceName === "admin",
                },
                    'group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',

                    withBack ? 'bg-blue-500' : 'bg-gray-200',
                    'focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-'
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    className={clsx(
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        withBack ? 'translate-x-5' : 'translate-x-0'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in',
                            withBack ? 'opacity-0 duration-100 ease-out' : 'opacity-100'
                        )}
                    >
                        <svg fill="none" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3")}>
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out',
                            withBack ? 'opacity-100 duration-200 ease-in' : 'opacity-0'
                        )}
                    >
                        <svg fill="currentColor" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3")}>
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </Switch>
        </div>

    )
}


const SwitchEmballageComponent = () => {
    const { isAuth, setIsAuth } = useAppContext();

    const setWithEmballage = ExpeditionStore((state) => state.setWithEmballage);
    const withEmballage = ExpeditionStore((state) => state.withEmballage);
    const setMaxStepper = ExpeditionStore((state) => state?.setMaxStepper);
    const maxStepper = ExpeditionStore((state) => state?.maxStepper);

    const emballageShipping = () => {

        // if (withEmballage) {
        //     setMaxStepper(maxStepper - 1)
        // } else {
        //     setMaxStepper(maxStepper + 1)
        // }
        setWithEmballage(!withEmballage)
    }

    return (
        <div className="flex items-center justify-between my-2">
            <span className='text-lg font-bold' >Souhaitez-vous une founiture d'emballage</span>
            <Switch
                checked={withEmballage}

                onChange={emballageShipping}

                className={clsx({
                    "bg-paris": isAuth?.spaceName === "paris" && withEmballage,
                    "bg-medical ": isAuth?.spaceName === "medical" && withEmballage,
                    "bg-service ": isAuth?.spaceName === "service" && withEmballage,
                    "bg-admin ": isAuth?.spaceName === "admin" && withEmballage,
                    "bg-blue-500": !withEmballage,
                },
                    'group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',

                    withEmballage ? 'bg-blue-500' : 'bg-red-900',
                    'focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-'
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    className={clsx(
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        withEmballage ? 'translate-x-5' : 'translate-x-0'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in',
                            withEmballage ? 'opacity-0 duration-100 ease-out' : 'opacity-100'
                        )}
                    >
                        <svg fill="none" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3")}>
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out',
                            withEmballage ? 'opacity-100 duration-200 ease-in' : 'opacity-0'
                        )}
                    >
                        <svg fill="currentColor" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3")}>
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </Switch>
        </div>

    )
}

