import React from 'react'
import { TemperatureSelector } from '../Utils/Selectors/TemperatureSelector'
import { ExpeditionStore } from '../../store/expedition'
import InputComponent from '../InputComponent/InputComponent';
import { ErrorStore } from '../../store/inputError';
import { Selector } from '../Utils/Selectors/Selector';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { useAppContext } from '../../context';
import { ButtonComponent } from '../ButtonComponent';
import { emballageValidator } from '../../utils/validators';
import { toast } from 'react-toastify';

export const Emballage = () => {

    const emballagepckage = ExpeditionStore((state) => state.emballagepckage);
    const updateEmballePckage = ExpeditionStore((state) => state.updateEmballePckage);
    const nextStepper = ExpeditionStore((state) => state.nextStepper);
    const errorMessage = ErrorStore((state) => state.errorField);
    const setMessageError = ErrorStore((state) => state.setMessageError);

    const returnTypeList = (value) => {
        if (value === '-80') {
            return emballageType
        } else if (value === "AMBi") {
            return emballageType3
        } else if (value === "-196") {
            return emballageType4
        } else {
            return emballageType2
        }
    }


    const handleNext = () => {
        const { error, errorData } = emballageValidator(emballagepckage);
        if (error) {
            toast.error(errorData.message, {
                position: "top-center",
            });
            setMessageError({ ...errorData })
        } else {
            nextStepper()
        }
    };

    const typeList = returnTypeList(emballagepckage.temperature)

    return (
        <section className={"content px-1"}>
            <div className={"grid grid-cols-2 gap-4 space-y-2 md:space-y-0 mx-1 "}>
                <TemperatureSelector selectedValue={emballagepckage.temperature} onChange={(value) => updateEmballePckage('temperature', value)} />

                <Selector onSelect={(value) => updateEmballePckage('gammeEmballage', value)} value={emballagepckage.type} label="Gamme d'emballage" data={typeList} isMulti={true} />
                <div>
                    <SwitchComponent value={emballagepckage.sonde} onUpdate={(value) => updateEmballePckage('sonde', value)} title="Souhaitez-vous une sonde de température" />

                    {emballagepckage.sonde && <Selector onSelect={(value) => updateEmballePckage('type', value)} value={emballagepckage.type} label="Type de sonde" data={sondeList} />}
                </div>

                <div>
                    <SwitchComponent value={emballagepckage.diagnobags} onUpdate={(value) => updateEmballePckage('diagnobags', value)} title="Souhaitez-vous des diagnobags supplémentaires" />

                    {emballagepckage.diagnobags &&
                        <div className="grid-cols-3 gap-3 md:grid">
                            <div className="col-span-2">
                                <Selector onSelect={(value) => updateEmballePckage('diagnobagsType', value)} value=
                                    {emballagepckage.diagnobagsType} label="Type de diagnobags" data={diagnobagsTypeList} />
                            </div>
                            <div>
                                <label htmlFor="diagnobagsNb" className="block mb-1 text-lg font-normal leading-6">Quantité</label>
                                <InputComponent type='number' value={emballagepckage.diagnobagsNb} onUpdate={(value) => updateEmballePckage('diagnobagsNb', value)} label="Nombre de diagnobags" />
                            </div>


                        </div>
                    }

                </div>
            </div>

            <div className='flex justify-end mt-3 flex-end '>
                <ButtonComponent title={"Suivant"} onClick={handleNext} />
            </div>
        </section>

    )
}


const emballageType = [
    { value: '5Kg', label: '5Kg' },
    { value: '10Kg', label: '10Kg' },
    { value: '15Kg', label: '15Kg' },
    { value: '20Kg', label: '20Kg' },
    { value: '30Kg', label: '30Kg' },
]

const emballageType2 = [
    { value: '4L', label: '4L' },
    { value: '11L', label: '11L' },
    { value: '16L', label: '16L' },
    { value: '20L', label: '20L' },
    { value: '30L', label: '30L' },
    { value: '33L', label: '33L' },
]


const emballageType3 = [
    { value: '0,75L', label: '0,75L' },
    { value: '2L', label: '2L' },
    { value: '3L', label: '3L' },
    { value: '6,25L', label: '6,25L' },
    { value: '12,50L', label: '12,50L' },
    { value: '18,75L', label: '18,75L' },
    { value: '25L', label: '25L' },
    { value: '37,50L', label: '37,50L' },
]


const emballageType4 = [
    { value: 'PETIT', label: 'PETIT' },
    { value: 'MOYEN', label: 'MOYEN' },
    { value: 'GRAND', label: 'GRAND' },
]

const sondeList = [
    { value: 'classique', label: 'Sonde T° classique(-30°C à +70°C) ', minTemperature: -30, maxTemperature: 70 },
    { value: 'classique-reutilisable', label: 'Sonde T° classique réutilisable(-30°C à +70°C) ', minTemperature: -30, maxTemperature: 70 },
    { value: 'geolocalisable', label: 'Sonde T° Géolocalisable(-10°C à 55°C)  ', minTemperature: -10, maxTemperature: 55 },
    { value: 'geolocalisable-glace-carbonique', label: 'Sonde T° Géolocalisable glace carbonique(-95°C à 55°C)', minTemperature: -95, maxTemperature: 55 },
    { value: 'glace-carbonique', label: 'Sonde T° Géolocalisable glace de carbonique(-95°C à 55°C)', minTemperature: -95, maxTemperature: 55 },
]

const diagnobagsTypeList = [
    { value: 'A2', label: 'A2' },
    { value: 'A3', label: 'A3' },
    { value: 'A4', label: 'A4' },
]

const SwitchComponent = ({ value, onUpdate, title }) => {
    const { isAuth, setIsAuth } = useAppContext();


    const backShipping = () => {
        onUpdate(!value)

    }

    return (
        <div className="flex items-center justify-between my-2">

            <span className='text-lg font-bold' >{title}</span>
            <Switch
                checked={value}

                onChange={backShipping}

                className={clsx({
                    "bg-paris": isAuth?.spaceName === "paris" && value,
                    "bg-medical ": isAuth?.spaceName === "medical" && value,
                    "bg-service ": isAuth?.spaceName === "service" && value,
                    "bg-admin ": isAuth?.spaceName === "admin" && value,
                },
                    'group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',

                    value ? 'bg-blue-500' : 'bg-red-900',
                    'focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-'
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    className={clsx(
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        value ? 'translate-x-5' : 'translate-x-0'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in',
                            value ? 'opacity-0 duration-100 ease-out' : 'opacity-100'
                        )}
                    >
                        <svg fill="none" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3 my-1")}>
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <span
                        aria-hidden="true"
                        className={clsx(
                            'absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out',
                            value ? 'opacity-100 duration-200 ease-in' : 'opacity-0'
                        )}
                    >
                        <svg fill="currentColor" viewBox="0 0 12 12" className={clsx({
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                        }, "w-3 h-3")}>
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </Switch>
        </div>

    )
}